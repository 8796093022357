<template>
  <div>
    <BaseLoading
      style="min-height: 400px; height: 90vh"
      v-if="loading"
    ></BaseLoading>

    <div v-else>
      <!-- <BaseHeader :title="$route.params.status"></BaseHeader> -->
      <section class=" order-listing-header-main px-2 mt-2  ">  
        <div class=" d-flex align-items-center justify-content-between header-elements-inline pb-0">
          <h2 class="card-title font-weight-black text-capitalize">
            {{ $route.params.status }}
          </h2>
          <div class="header-elements">
            <div class="">
              <!-- <router-link
                :to="{ name: 'PlaceOrder' }"
                class=" px-4 py-2  btn--custom ml-auto"
                >Order Now</router-link
              > -->
              <router-link  :to="{ name: 'PlaceOrder' }"  class="btn-main btn--custom" type="button" id="of-onbording">Order now</router-link>

            </div>
          </div>
        </div>
      
      </section>


      <div  v-if="isEditable && bulkActionIds.length > 0" class="card px-2 py-3 text-right my-1 py-0" style="">
        <div class="row">
          <div class="col-12 col-sm-8 text-left">
            <div
              v-if="isEditable && bulkActionIds.length > 0"
              class="d-flex flex-row align-items-center"
            >
              <!-- <input  type="checkbox" style="width: 22px !important" checked />  -->
              <span
                class="badge"
                style="
                  height: 22px;
                  text-align: center;
                  vertical-align: middle !important;
                  border: 1px solid rgb(32 150 243);
                  color: rgb(32 150 243);
                  background: rgb(32 150 243 / 21%);
                  width: 22px;
                  padding: 3px;
                "
              >
                <span class="icon-check"></span>
              </span>
              <label class="my-1 ml-1"
                >{{ bulkActionIds.length }} Orders Selected</label
              >

              <button
              style="border-radius:50px!important"
                @click="bulkAction('pay')"
                class="btn btn-success px-3 ml-2"
              >
                Pay
              </button>
              <!-- <select
                @change="bulkAction('pay')"
                class="form-control ml-2 pr-2"
                style="
                  max-width: 120px !important;
                  border-color: #c1c1c1 !important;
                  color: #2096f3;
                  font-weight: bold;
                "
              >
                <option>Action</option>
                <option value="pay">Pay</option>
              </select> -->
            </div>
          </div>
        </div>
      </div>
      <div class=" mx-0   order-table-custom ">
      
        <div class=" order-listing-header  row  p-3 mx-sm-0 mx-1 ">  
          <div class="col-1 text-left  "> Order Id </div>
          <div class="col-2 text-left ">Topic</div>
          <div class="col-1 text-left">Level</div>
          <div class="col-2 text-left"> Due</div>
          <div class="col-1 text-left">Pages</div>
          <div class="col-1 text-left">Charts</div>
          <div class="col-1 text-left"> Slides</div>
          <div class="col-1 text-left">Price</div>
          <div class="col-2 text-left">Action </div>
       </div>
     
       <div v-if="orders.length > 0" class="">
       <div v-for="(order, index) in orders" :key="index + 'ords'" class="order-listing-body row px-3 py-3 mx-sm-0 mx-1 ">  
          <div class="col-1 d-flex align-items-start justify-content-center text-left">
            <div v-if="isEditable">
              <input
                v-if="order.id"
                class=" ml-2"
                v-model="bulkActionIds"
                name="bulkActionIds"
                :id="`bulkActionIds${order.id}`"
                type="checkbox"
                :value="order.id"
                style="width: 18px !important; height: 18px !important"
              />
              <h3 class="my-0 font-weight-black" v-else>Total</h3>
            </div> 	
            <router-link class="px-2"
              :to="{ name: 'Order', params: { id: order.id } }"
              >{{ order.id || "" }}</router-link>
             
          </div>
          <div class="col-2 text-left">
            <router-link
              :to="{ name: 'Order', params: { id: order.id } }"
              >{{ order.title || "" }}</router-link
            >
            <div class="text-slate-500 text-xs whitespace-nowrap mt-0.5">{{ order.subject_area_text || "" }}</div>
          </div>
          <div class="col-1 text-left">{{ order.academic_level_text || "" }}</div>
          <div class="col-2 text-left"
          :class=" order.urgency_text.past_due
              ? 'text-danger'
              : 'text-success'
          "
          >  {{ order.urgency_text.date || "" }} </div>
          <div class="col-1 text-left">{{ order.pages || "" }}</div>
          <div class="col-1 text-left">{{ order.charts || "" }}</div>
          <div class="col-1 text-left"> {{ order.slides || "" }}</div>
          <div class="col-1 text-left">
            <span class="font-weight-bold" v-if="order.id"
            >${{ order.cost || "" }}</span
          >
          <h3 class="my-0 font-weight-black" v-else>
            ${{ order.cost || "" }}
          </h3>
          </div>
          <div class="col-2 text-left  ">
            <div class="btn-group" v-if="order.id">
            <router-link
              class="btn btn-outline-success btn-sm"
              :to="{ name: 'Order', params: { id: order.id } }"
              >View</router-link
            >
            <router-link
              v-if="isEditable"
              class="btn btn-success btn-sm"
              :to="{ name: 'payment', params: { id: order.id } }"
              >Pay</router-link
            >
            <router-link
              v-if="isEditable"
              class="btn btn-outline-success btn-sm"
              :to="{ name: 'EditOrder', params: { id: order.id } }"
              >Edit</router-link
            >
          </div>
          </div>
       </div>
      </div>

      </div>
      <div class="d-flex flex-column align-items-center justify-content-center w-100 "  v-if="orders.length < 1">
        
            <strong class="custom_imageEmpty  text-center" >No orders.</strong>
         
      </div>

      <div  v-if="orders.length >= 1" class="card px-2 py-3 d-flex flex-row align-items-center justify-content-between text-right my-1 py-0" style="">
        <div class="col-6">
          <div v-if="isEditable && bulkActionIds.length > 0" class="col-12  col-sm-8 text-left">
            <div
             
              class="d-flex flex-row align-items-center"
            >
              <!-- <input  type="checkbox" style="width: 22px !important" checked />  -->
              <span
                class="badge"
                style="
                
                  height: 22px;
                  text-align: center;
                  vertical-align: middle !important;
                  border: 1px solid rgb(32 150 243);
                  color: rgb(32 150 243);
                  background: rgb(32 150 243 / 21%);
                  width: 22px;
                  padding: 3px;
                "
              >
                <span class="icon-check"></span>
              </span>
              <label class="my-1 ml-1"
                >{{ bulkActionIds.length }} Orders Selected</label
              >

              <button
              style="border-radius:50px!important"
                @click="bulkAction('pay')"
                class="btn btn-success px-3 ml-2"
              >
                Pay
              </button>
              <!-- <select
                @change="bulkAction('pay')"
                class="form-control ml-2 pr-2"
                style="
                  max-width: 120px !important;
                  border-color: #c1c1c1 !important;
                  color: #2096f3;
                  font-weight: bold;
                "
              >
                <option>Action</option>
                <option value="pay">Pay</option>
              </select> -->
            </div>
          </div>
        </div>
        <div class="col-6">
          <p class="text-right">
          From {{ filters.from || "" }} to {{ filters.to || "" }} of
          {{ filters.total || "" }} orders
        </p>
          <div class="btn-group float-right" >
            <button 
            v-if="filters.prev_page_url"
              class="btn btn-outline-primary btn-sm"
              @click="navigate(filters.prev_page_url)"
              >← &nbsp; Prev</button>
            <button
              class="btn btn-primary btn-sm"
              
              >On Page {{ filters.current_page || "" }}</button
            >
            <button
              v-if="filters.next_page_url"
              class="btn btn-outline-primary btn-sm"
              @click="navigate(filters.next_page_url)"
              >Next &nbsp; →</button
            >
          </div>
         
        </div>
      </div>
      
     
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  name: "OrderListing",
  computed: {
    ...mapState("orders", ["orders", "filters"]),
    isEditable() {
      if (this.$route.params.status == "unpaid") {
        return true;
      } else return false;
    },
  },

  data() {
    return {
      loading: false,
      bulkActionIds: [],
    };
  },

  beforeRouteUpdate(to, from, next) {
    this.loading = true;
    let payload = to.params && to.params.status ? to.params.status : "editing";
    this._getOrders(payload)
      .catch((err) => console.log(err))
      .finally(() => (this.loading = false));
    next();
  },

  mounted() {
    this.loading = true;
    // console.log(this.$route.params.status);
    this._getOrders(this.$route.params.status || "editing")
      .catch((err) => console.log(err))
      .finally(() => (this.loading = false));
  },

  methods: {
    ...mapActions("orders", ["_getOrders", "_navigate"]),

    bulkAction(action) {
      if (action == "pay") {
        console.log("bulkActionIds", this.bulkActionIds);
        this.$router.push({
          name: "BulkCheckout",
          params: {
            ids: JSON.stringify(this.bulkActionIds),
          },
        });
      }
    },
    navigate(route) {
      this.loading = true;
      this._navigate(route)
        .catch((err) => console.log(err))
        .finally(() => (this.loading = false));
    },
  },
};
</script>

<style lang="scss" scoped>
.is-title {
  max-height: 3px !important;
  overflow-y: hidden;
  line-height: 1.5;
  overflow: hidden;
}
td {
  padding: 2px !important;
}
</style>
